import axios from '../../lib/axios';
import { useContext, useMemo } from 'react';
import { AppContext } from '../context/AppState/AppContext';
import { useAppArgs } from './useAppArgs';
import { useAuthContext } from '../context/AuthState/AuthContext';
import { throttle } from 'throttle-debounce';
const Fp = require('lodash/fp')

export const useNTFApi = () => {
  const { state: appState } = useContext(AppContext);
  const { fallbackDomain, tenant } = useAppArgs();
  const { workflowId, nodeId } = appState;
  const { state: authState } = useAuthContext();
  const authToken = authState.token;

  const ntfApi = useMemo(() => {
    async function getUserPool() {
      const response = await axios.post(
        `https://${tenant.region}.ntf.${fallbackDomain}/userpool/all`,
        {
          slug: tenant.slug,
          region: tenant.region,
        },
      );
      return response.data;
    }

    async function startWorkflow() {
      const response = await axios.post(
        `https://${tenant.region}.ntf.${fallbackDomain}/workflow/start`,
        {
          workflowId,
          nodeId,
          tenant: appState.tenant,
          dipa: false,
          hash: 'none',
          metadata: {
            stratus: true,
            tenant: appState.tenant,
          },
        },
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        },
      );
      return response.data.executionArn;
    }

    async function checkWorkflowStatus(arn: string): Promise<{
      arn: string;
      token: string;
      callbackUrl: string;
      processing: boolean;
    }> {
      const response = await axios.post(
        `https://${tenant.region}.ntf.${fallbackDomain}/workflow/ui`,
        {
          arn,
        },
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        },
      );
      console.log('checkWorkflowStatus.Response:', response.data)
      return response.data;
    }

    async function awaitWorkflowReady(arn: string): Promise<{
      arn: string;
      token: string;
      callbackUrl: string;
    }> {
      return new Promise(async (resolve, reject) => {
        let tries = 0;
        const intervalId = setInterval(async () => {
          try {
            tries++;
            if (tries > 10) {
              throw new Error('Reached maximum number of tries');
            }
            console.log('fetching workflow instance...');
            const instanceData = await checkWorkflowStatus(arn);
            if (instanceData.processing || Fp.isEmpty(instanceData.token)) {
              console.error('still processing..');
              return;
            }
            clearInterval(intervalId);
            return resolve(instanceData);
          } catch (err) {
            console.error(err);
            clearInterval(intervalId);
            return reject(err);
          }
        }, 1.5 * 1000);
      });
    }

    async function startWorkflowAndAwaitReady() {
      const arn = await startWorkflow();
      const instanceData = await awaitWorkflowReady(arn);
      return instanceData;
    }

    async function getDroppoint(arn: string) {
      const { data } = await axios.post(`https://${tenant.region}.ntf.${fallbackDomain}/workflow/ui`, {
        arn: arn
      });
      console.log(data)
      const drop = data?.drop
      return {
        dropid: drop?.id,
        username: drop?.username,
        password: drop?.password
      }
    }

    async function uploadFile(fileId: string, droppointCreds?: { dropid?: string, username?: string, password?: string }) {
      const body = !Fp.isEmpty(droppointCreds) ? {
        ...droppointCreds,
        fileId,
      } : {
        fileId
      }
      const response = await axios.post(`https://${tenant.region}.ntf.${fallbackDomain}/upload`, body)
      return response.data;
    }

    async function uploadFileToDroppoint(file: any, droppointInfo: any) {
      try {
        const formData = new FormData();
        formData.append('file', file);
        if (Fp.isEmpty(droppointInfo)) {
          throw new Error('Empty droppoint')
        }

        // await mockProgress(0, file.name);
        await axios.put(droppointInfo.url, file, {
          transformRequest: [
            (data, headers: any) => {
              console.log(headers);
              if (headers?.common?.Authorization) {
                delete headers.common?.Authorization;
              }

              return data;
            }
          ],
          headers: { ...droppointInfo.headers, 'Content-Type': file.type }
        });
        return true;
      } catch (error) {
        console.error('Error uploading to droppoint:', error);
        return false;
      }
    }

    async function getDroppointData(droppoint: any) {
      const { id, username, password } = droppoint
      const credentialString = btoa(`${username}:${password}`)
      const response = await axios.get(`https://${tenant.region}.ntf.${fallbackDomain}/drop/all/${id}`, {
        headers: { 'Authorization': `Basic ${credentialString}` }
      })
      return response.data;
    }

    async function getAllFilesFromDrop(droppoint: any) {
      const { id, username, password } = droppoint
      const credentialString = btoa(`${username}:${password}`)
      const response = await axios.get(`https://${tenant.region}.ntf.${fallbackDomain}/drop/all/${id}`, {
        headers: { 'Authorization': `Basic ${credentialString}` }
      })
      return response.data?.files ?? [];
    }

    async function addFilesToLicenseCount(size: number, fileNameConcatString: string) {
      // Update license utilization tracking
      try {
        const url = `https://${tenant.region}.ntf.${fallbackDomain}/license/usage`;
        const body = {
          filename: fileNameConcatString,
          fileSize: size,
          tenantId: appState.tenant.id
        }
        console.log('DEBUG --> PROCESSING_V2 --> LICENSE UTILIZATION', body);
        const response = await axios.post(url, body);
        console.log('DEBUG --> PROCESSING_V2 --> LICENSE UTILIZATION RESPONSE', response.status);
      } catch (e) {
        console.error(e);
      }
    }

    return {
      awaitWorkflowReady,
      checkWorkflowStatus,
      getUserPool,
      startWorkflow,
      startWorkflowAndAwaitReady,
      uploadFile,
      uploadFileToDroppoint,
      getDroppoint,
      getDroppointData,
      getAllFilesFromDrop,
      addFilesToLicenseCount,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tenant, appState, nodeId, fallbackDomain, workflowId, authToken]);
  const updateJobTimer = useMemo(() => {
    return throttle(30000,
      async (processId: string) => {
        try {
          const url = `https://${tenant.region}.ntf.${fallbackDomain}/people/job`;
          const body = {
            jobID: processId
          }
          const options = {
            headers: {
              Authorization: `Bearer ${authToken}`,
            },
          }
          await axios.put(url, body, options);
        } catch (e) {
          console.error(e);
        }
      }, { noTrailing: true }
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authToken]);
  return { ...ntfApi, updateJobTimer };
};
